#teamModal {
  width: 100%;
  position: relative;

  .slick-arrow {
    //icon size
    @include font-size(1.25,1.5625);

    .icon {
      fill: #B5B6BA;     
    }
  }

  .close-button {
    stroke:#B5B6BA;
    font-size:1em;

    @include breakpoint(small down) {
      font-size:0.8em;
    }
  }

  @include breakpoint(small down) {
    padding:2.6rem 0; 
  }
}