#slider { //Projektdetail
  position: relative;
  width: calc(100% + 20px);
  //@include breakpoint(large) {
    margin-bottom: rem-calc(50);
  //}
  .slider-item {
    margin-bottom: 0;
    margin-right: rem-calc(10);
    img {
      max-height: rem-calc(300);//height
    }
  }
  @include breakpoint(large) {
    //margin-left: rem-calc(230);//header: 250 width
    .slick-list {
      overflow: visible;
    }
    .slider-item {
      margin-bottom: 0;
      margin-right: rem-calc(30);
      img {
        max-height: rem-calc(400);//height
      }
    }
  }

  .slick-arrow {
    position: absolute;
    cursor: $pointerCursor;
    bottom: -4em;
    @include breakpoint(small down) {
      bottom: -3em;
      font-size:0.8em;
      transform: translateX(-1em);
    }

    .icon {
      fill: #B5B6BA;
    }
  }

  .next-arrow {
    right: 0;
  }
  .prev-arrow {
    right: 4em;
    transform: scaleX(-1);
  }



  @include breakpoint(xlarge) {
    .slider-item {
      img {
        max-height: rem-calc(500);//height
      }
    }
  }

}

@include breakpoint(large) {

  .slider-container {
    position: relative;
  }

}


#homeslide {
  .slick-track {
    height: 60vh;
    @include breakpoint(medium) {
      height: calc(100vh - 165px);
    }
  }
  .slick-slide {
    width: 100vw;
    position: absolute !important;
  }
  .slider-item {
    width: 100vw;
    height: 60vh;
    position: absolute !important;
    @include breakpoint(medium) {
      height: calc(100vh - 165px);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}


#team-slider {
  @include breakpoint(medium) {
    img {
      max-height:70vh;
      margin-left:auto;
    }
  }
  .slick-arrow {
    cursor: $pointerCursor;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .prev-arrow {
    transform: scaleX(-1);
    left:0;
  }
  .next-arrow {
    right:0;
  }
}

//Mobile arrows
#team-slider {
  .ctrimage {
    position: relative;
  }
  .mobilenext, .mobileprev {
    display: none;
    cursor: $pointerCursor;
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    .icon {
      fill:white;
    }
  }
  .mobileprev {
    left:1em;
    transform: scaleX(-1);
  }
  .mobilenext {
    right:1em;
  }

  @include breakpoint(small down) {
    .slick-arrow {
      display:none !important;
    }
    .mobilenext, .mobileprev {
      display: block;
    }
    .infotext {
      margin:0.5em 0.75em;
    }
    .ctrimage {
      //remove foundation margin of grid-cell
      margin-left:0;
      width:100%;
    }
  }
}