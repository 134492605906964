.logo {
  padding: rem-calc(20 10 20 0);
  @include breakpoint(large) {
    padding: rem-calc(50 20 50 0);
  }
  a {
    display: inline-block;
    img {
      width: 229px;
      height: auto; //immer width und height in PX angeben (IE)
      @include breakpoint(large) {
        width: 360px;
      }
    }
  }
}

#animated-logo {
  width: rem-calc(200);
  height: auto;
  @include breakpoint(large) {
    width: rem-calc(310);
  }
  .st0 {
    fill: $red;
  }
  .st1 {
    fill: $light-gray;
  }
  .redtogray {
    fill: $red;
    transition: all .3s ease-in-out;
  }
  .fadein {
    transition: all .3s ease-in-out;
    opacity: 0;
  }
  &:hover {
    .redtogray {
      fill: $light-gray;
    }
    .fadein {
      opacity: 1;
    }
  }
}