img {
  image-rendering: -webkit-optimize-contrast;
}

.main-img {
  position: relative;
  height: 60vh;
  @include breakpoint(medium) {
    height: calc(100vh - 165px);
  }


  .arrowdown {
    position: absolute;
    bottom: rem-calc(10);
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
    cursor: $pointerCursor;
    width: rem-calc(28);
    height: rem-calc(53);
    @include breakpoint(medium) {
      bottom: rem-calc(20);
    }
    .icon {
      fill: $white;
    }
  }
}