//progress circle
.progress-wrap {
  position: fixed;
  right: 3em;
  bottom: 3em;
  height: 46px;
  width: 46px;
  cursor: $pointerCursor;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  &:hover {
    svg {
      &.progress-circle path {
        stroke: $primary-color;
      }
      &.progress-arrow-up path {
        fill: $primary-color;
      }
    }
  }
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: $primary-color;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg.progress-arrow-up {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1em;
  width: 1em;
  path {
    fill: $primary-color;
  }
}
