ul:not(.menu):not(.form):not(.babel-meta) {
  margin-left: rem-calc(14);
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      content: "– ";
      margin-left: rem-calc(-14);
      margin-right: 0;
      padding-right: rem-calc(3);
    }
  }
}

.babel-meta {
  margin-top: rem-calc(58);
  margin-left: rem-calc(20);
  list-style-type: none;
  li {
    display: inline-block;
    font-size: rem-calc(15);
    a {
      color: $light-gray;
      &.active {
        color: $black;
      }
    }
    &:last-child {
      margin-left: rem-calc(6);
    }
  }
}