.buero-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  margin-top:1em;
  margin-bottom:1em;

  //grid-area: <row-start> / <column-start> / <row-end> / <column-end>
  .image0 { grid-area: 1 / 1 / 4 / 2; }
  .image1 { grid-area: 4 / 1 / 8 / 2; }
  .image2 { grid-area: 1 / 2 / 8 / 3; }
  .image3 { grid-area: 1 / 3 / 5 / 4; }
  .image4 { grid-area: 5 / 3 / 8 / 4; }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include breakpoint(small down) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(10, 1fr);
    
      .image0 { grid-area: 1 / 1 / 4 / 2; }
      .image1 { grid-area: 4 / 1 / 8 / 2; }
      .image2 { grid-area: 1 / 2 / 8 / 3; }
      .image3 { grid-area: 8 / 1 / 11 / 2; }
      .image4 { grid-area: 8 / 2 / 11 / 3; }
  }
}