.grid-container {
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  @include breakpoint(large) {
    padding-left: rem-calc(40);
    padding-right: rem-calc(40);
  }
}

.text-padding-right {
  @include breakpoint(large) {
    margin-right: rem-calc(40);
  }
}

.team-right-column {
  @include breakpoint(1780) {
    padding-left: 66px;
  }
}