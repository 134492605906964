#search-modal {
  top: 0 !important;
  left: 0;
  width: 100%;
  padding: 0;
  padding-top: 45vh;
  margin: 0;
  max-width: none;
  .sisea-search-form {
    input {
      border: none;
      font-size: rem-calc(30);
      font-weight: bold;
    }
  }
}

.search-top {
  cursor: $pointerCursor;
}


.simplesearch-paging {
  margin-bottom:1em;
}