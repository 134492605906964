//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }


@font-face {
  /*Neue Helvetica Medium*/
  font-family: 'Neue Helvetica';
  font-weight:500;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/c86f4d57-4caf-4c6f-9554-7926dd67e3f1.woff2') format('woff2'),
  url('../fonts/92c69f6c-0185-4eb2-800e-1ee3947ca4dd.woff') format('woff');
}


main {
  //Dynamic Font Size
  //                 mobile,desktop
  @include font-size(1.25,1.5625);
}

a:focus {
  outline: none;
}

h1, h2 {
  @include font-size(1.5625,1.875);
  margin: 0.6em 0 0.7em 0;
}

#teamModal .infotext {
  @include font-size(1.25,1.5625);

  h2 {
    margin-top:-0.3em;
  }
}

