// html.sr {
//   .fade-in-interval, .fade-in-news, .fade-in-bottom {
//     visibility: hidden;
//   }
// }

.remove-last-p-margin {
    p:last-child {
        margin-bottom:0;
    }
}