#grid {
  margin-bottom: 3em;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.grid-item, .grid-sizer {
  width: 50%;
  padding: 0 7.5px 15px 7.5px;
  @include breakpoint(medium) {
    width: 33.3%;
  }
  @include breakpoint(large) {
    width: 25%;
  }
}

.grid-item {
  transition: all .2s ease-in-out;

  .grid-item-content {
    position: relative;
    overflow: hidden;
    img {
      transition: all .2s ease-in-out;
    }
  }
  .full-link {
    cursor:inherit;
    padding: rem-calc(15 20);
    font-size: rem-calc(16);
    transition: all .2s ease-in-out;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba($red,0.5);
    color: #fff;
    transform: translateY(100%);
    h3 {
      @include font-size(1.25,1.5625);
      //font-size: rem-calc(20);
      //margin-bottom: rem-calc(15);
      text-transform: uppercase;
    }
  }
  &:hover {
    .full-link {
      transform: translateY(0);
    }
  }

}

//Plus Icon
.grid-item .full-link h3 {
  display:flex;
  gap:1em;
  justify-content: space-between;
  align-items: flex-start;
  gap:1em;
  .iconplus {
    flex-shrink: 0;
    width:rem-calc(15);
    height:rem-calc(15);
    margin-top:0.4em;
  }
}
@include breakpoint(small down) {
  .grid-item .full-link * {
    //mobile: no content at hover
    display:none !important;
  }
}

//Filterung
.filter {
  margin-bottom:2em;
  @include font-size(0.9375,1.375);
  @include breakpoint(small down) {
    line-height:2;
  }
  .label {
    background-color: transparent;
    color: #B5B6BA;
    padding: 0;
    margin: 0 rem-calc(25) 0 0;
    border: none;
    text-transform: none;
    font-size: inherit;
    font-weight: inherit;
    &.is-checked {
      color:$black;
    }
    &:hover {
      color: $red;
    }
  }
}

@include breakpoint(medium down) {
  //Reihenfolge invertiert bei mobile!
  .ctr-slider-filter {
    display:flex;
    flex-direction: column;

    .filter {
      order:-1;
    }
  }
}