.cookieconsent {
  position: fixed;
  top: auto !important;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($black,.95);
  color: $white;
  padding: rem-calc(10 20);
  z-index: 99999999 !important;
  @include breakpoint (medium) {
    left: auto;
    width: rem-calc(400);
  }
  p {
    margin: 0;
  }
  a {
    color: $red;
    &:hover {
      //color: #f4efb7;
    }
  }
  .button {
    font-size: 0.8rem;
    background: transparent;
    border-color: $white;
    color: $white;
    margin: rem-calc(10) auto;
    display: block;
    &:hover {
      background: rgba($white,0.2);
    }
  }
}
