footer {
  padding: rem-calc(20 0);
  font-size: rem-calc(15);

  a {
    margin-right:2.5em;

    @include breakpoint(small down) {
      display:block;
    }
  }
}
