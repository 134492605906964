$normalCursor: url('data:image/svg+xml;utf8,<svg width="36" height="36" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle style="fill: none; stroke: rgb(159, 90, 90); stroke-width: 5px" cx="50" cy="50" r="48"></circle></svg>') 18 18, auto;
$pointerCursor: url('data:image/svg+xml;utf8,<svg width="36" height="36" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle style="fill: rgb(159, 90, 90);" cx="50" cy="50" r="50"></circle></svg>') 18 18, auto;

html, html * {
    //Neu soll immer der gefuellte Kreis erscheinen, Issue #30
    //cursor: $normalCursor;
    cursor: $pointerCursor !important;
}

a {
    cursor: $pointerCursor;
}

.close-button {
    cursor: $pointerCursor;
}
