body {
  overflow-x: hidden;
}

@keyframes slideaway {
  from {transform: translateY(0);}
  to {transform: translateY(-100vh);}
}

.start-overlay {
  display:none;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 999999;
  background-color: $red;
  padding: rem-calc(15);
  padding-top: 40vh;
  cursor: $pointerCursor;
  transition: all .25s ease-in-out;
  @include breakpoint(medium) {
    padding: rem-calc(30);
    padding-top: 30vh;
  }
  @include breakpoint(large) {
    padding: rem-calc(50);
  }
  img {
    width: 100%;
    height: auto;
  }

  &.closed {
    opacity: 0;
    animation-name: slideaway;
    animation-duration: 0.1s;
    animation-delay: .3s;
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation-fill-mode: forwards;
  }

  iframe {
    aspect-ratio: 3.582;
    z-index: 1;
  }

  .clickfield {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: $pointerCursor;
  }

}




