.h-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 98;
  transition: all 0.2s ease-in-out;
  &.opaque {
   // @include shadow(0 1px 0 0 rgba($black, 0.1));
  }
}
