/*
.button {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
}
*/

.search-top {
  font-size: rem-calc(12);
  @include breakpoint(large) {
    margin: rem-calc(54 0 0 20);
  }
}
@include breakpoint(medium down) {
  .search-top {
    display: block;
    margin: rem-calc(20) auto;
  }
}

.team-btn {
  font-weight: 500;
  font-size: inherit;
  cursor: $pointerCursor;
  color: $black;
  transition: all .15s ease-in-out;
  &:hover {
    color: $red;
  }
}