#hamburger {
  position: absolute;
  right: 0;
  width: rem-calc(20);
  margin: rem-calc(23 20 20 20);
  z-index: 99;

  @include breakpoint(large) {
    display: none;
  }
  button {
    position: relative;
    width: rem-calc(20);
    height: rem-calc(24);
    display: block;
    cursor: $pointerCursor;
  }
  span {
    display: block;
    background-color: $light-gray;
    position: absolute;
    width: 100%;
    height: rem-calc(2);
    top: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    + span {
      top: rem-calc(8);
      + span {
        top: rem-calc(16);
      }
    }
  }
  &.is-active {
    span {
      transform: rotate(45deg);
      top: rem-calc(10);
      + span {
        opacity: 0;
        + span {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  &:hover {
    cursor: $pointerCursor;
  }
}
