$form-input-border-radius: 0.1em;
$form-transition: all 0.3s ease-in-out;
$radio-diameter-outer: 1em; // to center, you halso have to change transform on the radio/checkbox input
$radio-diameter-inner: 0.5em;
$radio-background-hover: rgba($black, 1);
$radio-background-focus: rgba(blue, 0.2);
// overwrite other variables (like $input-border) in settings

form {
  label {
    color: $form-label-color;
    font-size: $input-font-size;
    font-family: $input-font-family;
    line-height: $input-line-height;
  }

  input[type="radio"],
  input[type="checkbox"] {
    cursor: $pointerCursor;
    transform: translateY(0.2em);
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;
    color: $input-color;
    width: $radio-diameter-outer;
    height: $radio-diameter-outer;
    border: 0.15em solid $input-border-color;
    border-radius: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: $radio-diameter-inner;
      height: $radio-diameter-inner;
      border-radius: 100%;
      transform: scale(0) translate(0, 0); // have to set translate(0, 0) so transition works (otherwise there is not "starting translate"
      transition: $form-transition;
      background: $input-border-color-focus;
    }

    &:checked::before {
      transform: scale(1) translate(-50%, -50%);
    }

    &:focus {
      box-shadow: 0 0 0 0.2em $radio-background-focus;
    }
  }

  .input-container {
    position: relative;
    margin-bottom: 1.2em;

    input,
    textarea {
      color: $input-color;
      font-size: $input-font-size;
      font-family: $input-font-family;
      line-height: $input-line-height;
      background: $input-background;

      &:focus {
        background: $input-background-focus;

        & + label {
          border: $input-border-focus;
          box-shadow: $input-shadow-focus;
          color: $input-border-color-focus;

          span {
            background: $input-background-focus;
          }
        }
      }

      &:focus,
      &.not-empty {
        & + label span {
          transform: translateY(-50%);
          top: 0;
          font-size: 70%;
          line-height: 1;
          padding: 0 calc($input-padding/2);
          left: calc($input-padding/2);
        }
      }

      &:disabled + label {
        background: $input-background-disabled;
      }

      &.is-invalid-input {
        color: $input-error-color;

        &:focus + label,
        &.not-empty + label,
        & + label {
          color: $form-label-color-invalid;
          border-color: $alert-color;
        }
      }
    }

    label {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      border: $input-border;
      border-radius: $input-radius;
      pointer-events: none;
      box-shadow: $input-shadow;

      span {
        position: absolute;
        top: $input-padding;
        left: $input-padding;
        display: inline-block;
        transform: translateY(0);
        transition: $form-transition;
        background-color: $input-background;
      }

      abbr,
      abbr[title] {
        border-bottom: none;
      }
    }

    span.form-error {
      position: absolute;
      margin: 0;
      bottom: -5px;
      left: $input-padding;
      color: $alert-color;
      font-size: 0.7 * $input-font-size;
      line-height: 1;
      transform: translateY(100%);
    }
  }

  fieldset {
    ul {
      list-style-type: none;
      margin-left: 0;

      li {
        input {
          &[type="text"] {
            width: auto;
            display: inline-block;
          }

          &[type="radio"],
          &[type="checkbox"] {
            &.is-invalid-input {
              border-color: $alert-color;
            }
          }
        }
      }
    }
  }

  .form-error {
    text-align: right;
    font-size: $input-font-size;
    margin-top: 1em;

    span {
      max-width: 70ch;
      color: $alert-color;
    }
  }

  button[type="submit"] {
    display: block;
    margin-left: auto;
  }
}
