#menu-desktop,
#menu-offcanvas {
  .menu {
    font-size: rem-calc(25);
    li {
      a {
        &::after {
          //remove default
          border: none;
          //add own "arrow"
          position: absolute;
          right: 0;
          top: 11px;
          width: 40px;
          height: 30px;
          z-index: 2;
          background-color: $primary-color;
          /*
          using mask to change background image svg color with background-color
          */
          //maybe need to add -webkit to every mask property to function in all browsers!?
          //-webkit-mask-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 17" style="enable-background:new 0 0 28 17; fill:white;" xml:space="preserve"><path d="M2.1,0c0.6,0,1.1,0.2,1.5,0.6L14,11.1L24.4,0.6C24.8,0.2,25.3,0,25.9,0s1.1,0.2,1.5,0.6S28,1.5,28,2.1 c0,0.6-0.2,1.1-0.6,1.5L14,17L0.6,3.6C0.2,3.2,0,2.7,0,2.1s0.2-1.1,0.6-1.5C1,0.2,1.5,0,2.1,0z"/></svg>');
          mask-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 17" style="enable-background:new 0 0 28 17; fill:white;" xml:space="preserve"><path d="M2.1,0c0.6,0,1.1,0.2,1.5,0.6L14,11.1L24.4,0.6C24.8,0.2,25.3,0,25.9,0s1.1,0.2,1.5,0.6S28,1.5,28,2.1 c0,0.6-0.2,1.1-0.6,1.5L14,17L0.6,3.6C0.2,3.2,0,2.7,0,2.1s0.2-1.1,0.6-1.5C1,0.2,1.5,0,2.1,0z"/></svg>');
          //-webkit-mask-size: 16px 10px;
          mask-size: 16px 10px;
          mask-repeat: no-repeat;
          mask-position: center center;
          transition: all 0.2s ease-in-out;
          &[aria-expanded="true"] {
            transform: rotate(180deg);
          }
          &:hover {
            opacity: 0.6;
          }
        }
        &:hover {
          color: $red;
        }
      }
      &.active {
        > a {
          background: transparent;
          //color: $red;
          color:black;
          &:hover {
            color: $red;
          }
          text-decoration: underline;
          &::after {
            background-color: white;
          }
        }
      }
    }
  }
  .submenu {
    font-size: rem-calc(20);
  }
}

#menu-desktop {
  float: right;
  margin-top: rem-calc(43);
  .menu li a::after {
    right: -10px;
  }
  .menu {
    li {
      @include breakpoint(xlarge) {
        margin-right: rem-calc(40);
      }
    }
  }
}

#menu-offcanvas {
  padding: rem-calc(160 20 20 20);
  .menu {
    li {
      a {
        border-bottom: 1px solid $light-gray;
        text-align: center;
        padding: rem-calc(25 16);
      }
    }
  }
}
